.FeaturesImageWrapper {
    position: relative;
    z-index: -1;
}
.FeaturesImageWrapper img {
    display: block;
}
.FeaturesImageWrapper::before {
    content: "";
    position: absolute;
    top: calc(15% - 4.5%);
    left: calc(-25% - 4.5%);
    width: calc(150% + 9%);
    height: calc(70% + 9%);
    border-radius: 100rem;
    border: 4px dotted #8d8d8d;
    z-index: -1;
    animation: rotate 100s reverse linear infinite;
    opacity: .6;
}
.FeaturesImageWrapper::after {
    content: "";
    position: absolute;
    top: calc(15% - 10%);
    left: calc(-30% - 10%);
    width: calc(160% + 20%);
    height: calc(70% + 20%);
    border-radius: 100rem;
    border: 4px dotted #8d8d8d;
    z-index: -1;
    animation: rotate 120s normal linear infinite;
    opacity: .25;
}
@keyframes rotate {
    0% {
        transform: rotate3d(0, 0, 1, 0deg);
    }
    25% {
        transform: rotate3d(0, 0, 1, 90deg);
    }
    50% {
        transform: rotate3d(0, 0, 1, 180deg);
    }
    75% {
        transform: rotate3d(0, 0, 1, 270deg);
    }
    100% {
        transform: rotate3d(0, 0, 1, 360deg);
    }
}