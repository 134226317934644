.CrossButton {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #b41c18;
    color: #ffffff;
    border-radius: 0 2rem 0 1rem;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 2.75rem;
    border: 0;
    outline: none;
    padding: .6rem 1.2rem;
    line-height: 1;
    display: block;
    cursor: pointer;
    transition: background-color .25s ease;
}
.CrossButton:hover {
    background-color: #7d1310;
}
